:local {
  .switcher {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
  }

  .button {
    display: block;
  }
}
